import styled from "styled-components";

export const Content = styled.div`
  background: #fff;
  border-radius: 4px;
  padding: 45px 35px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  border: 1px solid #ddd;

  @media (max-width: 768px) {
    margin-top: 15px;
    padding: 0px !important;
    border: 0;
  }

  h1 {
    font-size: 28px;
  }
`;
