import React from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

import { Container } from "./styles";

export default function SkeletonMain({ sideFixed }) {
  return (
    <Container sideFixed={sideFixed}>
      <div>
        <Skeleton height={35} width={400} />
        <Skeleton height={150} />
        <br />
        <Skeleton height={25} width={400} />
        <Skeleton height={130} />
        <Skeleton width={220} height={44} />
        <br />
        <Skeleton height={25} width={400} />
        <Skeleton height={130} />
        <Skeleton width={220} height={44} />
      </div>
    </Container>
  );
}

SkeletonMain.propTypes = {
  sideFixed: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

SkeletonMain.defaultProps = {
  sideFixed: null
};
