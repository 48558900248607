import React from "react";

import { Container, Content } from "./styles";

export default function University() {
  return (
    <Container>
      <Content>
        <h1>Universidade Serafine</h1>
        <p>
          Bem-vindo à Universidade Serafine, uma plataforma com muito conteúdo e
          informações. Aqui você terá acesso à treinamentos e documentos de
          diversas áreas para te auxiliar na sua capacitação e na da sua equipe.
          Com os treinamentos, você será capaz de fazer a gestão da sua loja de
          forma profissional para alcançar melhores resultados.
        </p>
      </Content>
    </Container>
  );
}
